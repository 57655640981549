import { RiderCategory, RiderType } from "src/api/types/rider-type";
import { boolean } from "yup";
import { IPaginatedResult } from "./axios-response";
import { IVehicleListItem } from "./vehicle";
import { IMiniZoneData } from "./zones";

export type TVehicleType = "None" | "Bicycle" | "MotorCycle" | "Car" | "Truck";

export enum RiderSorts {
  None = "None",
  ByNameAsc = "ByNameAsc",
  ByRegisterDateDesc = "ByRegisterDateDesc",
}

export interface IBaseRider {
  id: string;
  firstName: string;
  lastName: string;
  email?: string;
  avatar: string;
  employeeId: string;
  registerDate: string;
  zone: IMiniZoneData;
  vehicle: IVehicleListItem;
  isActive: boolean;
}

export interface IRiderListItem extends IBaseRider {
  civilIdNo: string;
  driverLicenseNo: string;
  userId: string;
  contactNo: string;
  riderType: RiderType;
  riderCategory: RiderCategory;
  currentWorkingStatus: IWorkingStatus;
  nextWorkingStatus: IWorkingStatus;
  targetGroup: ITargetGroup;
}

export interface IRidersListPayload extends IPaginatedResult<IRiderListItem> {}

export interface INewRiderData {
  firstName: string;
  lastName: string;
  email?: string;
  civilIdNo: string;
  driverLicenseNo: string;
  contactNo: string;
  rawPassword: string;
  employeeId: string;
  file?: File;
  riderType: RiderType;
  riderCategory: RiderCategory;
  currentWorkingStatusId: number;
  nextWorkingStatusId: number;
  WorkingStatusId: number;
  targetGroupId?: string;
}

export interface IMiniRiderData {
  id: string;
  firstName: string;
  lastName: string;
  employeeId: string;
  avatar: string;
  riderType: RiderType;
}

export type TUniqueFields = "Email" | "CivilIdNo" | "EmployeeId";

export interface IInactiveRiderItem {
  name: string;
  phone: string;
  zone: string;
  checkOutDuration: number;
}

export interface IInactiveRidersData
  extends IPaginatedResult<IInactiveRiderItem> {}

export interface IIdleRiderItem {
  riderId: string;
  name: string;
  phone: string;
  zone: string;
  idleDuration: number;
  workingStatusTitle: string;
  workingStatusId: number;
  isIdle?: boolean;
}

export interface IIdleRidersPayload extends IPaginatedResult<IIdleRiderItem> {}

export interface IUnreachableRidertem {
  riderId: string;
  name: string;
  phone: string;
  zone: string;
  unreachableTime: string;
  isUnreachable?: boolean;
}

export interface RiderWorkingStatusItem {
  riderId: string;
  workingStatusTitle: string;
  workingStatusId: number;
}

export interface IUnreachableRidersPayload
  extends IPaginatedResult<IUnreachableRidertem> {}

export type IWorkingStatus = {
  id: number;
  title: string;
};

export type ITargetGroup = {
  id: number;
  title: string;
};
