import { Box, Input } from "@chakra-ui/react";
import React, { memo, useEffect, useState } from "react";
import HorizontalLoading from "src/components/app/HorizontalLoading";
import { panelRidersService } from "../../../../api/services/riders";
import RidersSelect from "./index";
import { debounce } from "lodash";
import { RiderSorts } from "../../../../api/types/riders";

interface RidersSelectProps {
  onRiderSelected: (zones: any[]) => void;
  onAllSelected: (value: boolean) => void;
  allSelectedCount: number;
}

const RidersSelectContainer = memo(
  ({ onRiderSelected, onAllSelected, allSelectedCount }: RidersSelectProps) => {
    const [loadingZoneRiders, setLoadingZoneRiders] = useState(false);
    const DEFAULT_SELECT_COUNT = 20;
    const DEFAULT_SELECT_OFFSET = 0;
    const [searchDisable, setSearchDisable] = useState<boolean>(false);
    const [searchQuery, setSearchQuery] = useState<string>("");
    const [refreshFetch, setRefreshFetch] = useState(0);
    const [ridersList, setRidersList] = useState<any[]>([]);
    const [totalCount, setTotalCount] = useState(0);
    const [currentOffset, setCurrentOffset] = useState<number>(
      DEFAULT_SELECT_OFFSET
    );
    const [hasMore, setHasMore] = useState<boolean>(true);
    const [selectLoading, setSelectLoading] = useState<boolean>(false);
    const abortControllerRef = React.useRef<AbortController | null>(null);
    const onPageEnded = () => {
      if (!selectLoading && hasMore) {
        fetchRidersList(DEFAULT_SELECT_COUNT);
      }
    };

    const onItemsChanged = (data: any[]) => {
      setRidersList(data);
    };

    const debouncedSearch = React.useMemo(
      () =>
        debounce(() => {
          setRefreshFetch((val) => val + 1);
        }, 500),
      []
    );

    const onSearchQueryChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
      setSearchQuery(e.target.value);
      debouncedSearch();
    };

    const fetchRidersList = async (offset?: number, resetOffset?: boolean) => {
      setSelectLoading(true);

      // Cancel previous request if exists
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }

      // Create new AbortController for this request
      abortControllerRef.current = new AbortController();

      try {
        const offsetCount = resetOffset
          ? DEFAULT_SELECT_OFFSET
          : offset
          ? currentOffset + offset
          : currentOffset;
        const res = await panelRidersService.getAll(
          offsetCount,
          DEFAULT_SELECT_COUNT,
          RiderSorts.ByNameAsc,
          abortControllerRef.current.signal,
          searchQuery
        );

        const ridersListMap = res.data.data.items.map((item: any) => ({
          id: item.id,
          text: item.employeeId + "_" + item.firstName + " " + item.lastName,
          searchableFields: {
            nameEn: (item.firstName + " " + item.lastName).trim(),
          },
        }));

        setHasMore(ridersListMap.length === DEFAULT_SELECT_COUNT);
        setTotalCount(res.data.data.totalCount || 0);
        setRidersList((prevList) => [...prevList, ...ridersListMap]);
        setCurrentOffset(offsetCount);
      } catch (error: any) {
        // Only log error if it's not a cancellation
        if (error.message !== "canceled") {
          console.error(error);
        }
      } finally {
        setSelectLoading(false);
      }
    };

    useEffect(() => {
      setRidersList([]);
      setCurrentOffset(0);
      fetchRidersList(0, true);

      return () => {
        // Cleanup: abort any pending request when component unmounts
        if (abortControllerRef.current) {
          abortControllerRef.current.abort();
        }
      };
    }, [refreshFetch]);

    useEffect(() => {
      return () => {
        debouncedSearch.cancel();
      };
    }, []);

    return (
      <Box cursor={loadingZoneRiders ? "wait" : "default"}>
        <Box mb="4">
          <Input
            type="search"
            placeholder="Search by name"
            size="lg"
            disabled={searchDisable}
            value={searchQuery}
            onChange={onSearchQueryChanged}
          />
        </Box>
        {loadingZoneRiders && (
          <Box p="4" textAlign="center">
            <HorizontalLoading />
          </Box>
        )}
        <RidersSelect
          items={ridersList}
          totalCount={totalCount}
          onChange={onItemsChanged}
          onSelected={(data: any) => onRiderSelected(data)}
          disabled={loadingZoneRiders}
          hasMore={hasMore}
          loading={selectLoading}
          onScrollToEnd={onPageEnded}
          onAllSelected={(value) => {
            setSearchDisable(value);
            onAllSelected && onAllSelected(value);
          }}
          allSelectedCount={allSelectedCount}
        />
      </Box>
    );
  }
);

export default RidersSelectContainer;
