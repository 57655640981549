import React, { memo, useEffect, useMemo, useRef, useState } from "react";
import { Box, Text, Button, IconButton } from "@chakra-ui/react";
import Table from "src/components/app/Table";
import Icon from "@mdi/react";
import { mdiPlus } from "@mdi/js";
import { useTranslation } from "react-i18next";
import { useTitle } from "src/hooks/useTitle";
import { Pagination } from "src/components/app/Pagination";
import { SearchInput } from "src/components/app/SearchInput";
import { useAsync } from "src/hooks/useAsync";
import { IFetchDataResponse } from "src/types/general";
import useQueryParam from "src/hooks/useSearchQuery";
import { useAbortController } from "src/hooks/useAbortController";
import { useTable } from "src/hooks/useTable";
import { ICustomOption } from "src/types/table";
import { modalActions } from "src/global-context/modals";
import { MODAL_TYPES } from "src/types/modals";
import { messagesService } from "src/api/services/messages";
import { IMessageListItem } from "src/api/types/messages";
import dateHelper from "src/utils/date-helper";
import RiderListModal from "../../../components/app/Modal/RidersList/Modal";

interface IFetchMessagesFilters {
  offset: number;
  size: number;
  query?: string;
}

type TCustomOptions = {
  createdAt: ICustomOption<{ createdAt: string }>;
};

const ActionIcon = () => {
  return (
    <>
      <svg
        width="28"
        height="28"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.31008 2.01517C9.21922 2.92431 9.21922 4.39831 8.31008 5.30745C7.40094 6.21659 5.92693 6.21659 5.01779 5.30745C4.10865 4.39831 4.10865 2.92431 5.01779 2.01517C5.92693 1.10603 7.40094 1.10603 8.31008 2.01517"
          stroke="white"
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.57403 8.53062C8.70803 8.18462 7.69203 7.99396 6.66536 7.99396C3.9667 7.99396 1.33203 9.30529 1.33203 11.322V11.9886C1.33203 12.3566 1.6307 12.6553 1.9987 12.6553H8.27403"
          stroke="white"
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.3333 14.6667C9.49267 14.6667 8 13.1747 8 11.3333C8 9.53067 9.53333 7.998 11.336 8C13.176 8.00134 14.6667 9.49334 14.6667 11.3333C14.6667 13.174 13.1747 14.6667 11.3333 14.6667"
          stroke="white"
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.8142 10.5927L10.9622 12.4447L9.85156 11.3333"
          stroke="white"
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
};
const Notifications: React.FC = memo(() => {
  const { t } = useTranslation();
  useTitle(t("pageTitles.notifications"));
  const [isRiderModalOpen, setIsRiderModalOpen] = useState<boolean>(false);
  const [currentItem, setCurrentItem] = useState(null);

  const columnsTable = useMemo(
    () => [
      {
        title: t("fields.date"),
        dataIndex: "createdAt",
        isSortable: true,
        isHidden: false,
      },
      {
        title: t("fields.title"),
        dataIndex: "title",
        isSortable: true,
        isHidden: false,
      },
      {
        title: t("fields.message"),
        dataIndex: "body",
        isSortable: true,
        isHidden: false,
      },
    ],
    []
  );

  const customOption: TCustomOptions = useMemo(
    () => ({
      createdAt: {
        title: "Date",
        dataIndex: "createdAt",
        render: (_, item) => (
          <Box as="p">
            {dateHelper.formatDate(item.createdAt, "YYYY-MM-DD HH:mm")}
          </Box>
        ),
      },
      actions: {
        title: "Action",
        dataIndex: "actions",
        render: (_: any, item: any) => (
          <IconButton
            aria-label="Test"
            icon={<ActionIcon />}
            color="gray.100"
            bg="blue.300"
            onClick={() => {
              setCurrentItem(item);
              setIsRiderModalOpen(true);
            }}
          >
            {item.id}
          </IconButton>
        ),
      },
    }),
    []
  );

  const exportButton = useRef<any>();
  const { getAbortSignal } = useAbortController();
  const { queryParams, updateQueryParams, initialized } = useQueryParam({
    initialFields: {
      size: 10,
      offset: 0,
      query: "",
    },
  });

  const {
    run: fetchMessages,
    isLoading,
    data: messagesData,
  } = useAsync<IFetchDataResponse<IMessageListItem>, IFetchMessagesFilters>(
    (options) => {
      const { offset, size, query = queryParams.query } = options;
      return new Promise(async (resolve, reject) => {
        try {
          const response = await messagesService.getAll(
            offset,
            size,
            getAbortSignal("fetchMessages").signal,
            query
          );
          resolve(response.data.data as IFetchDataResponse<IMessageListItem>);
        } catch (e) {
          reject([]);
        }
      });
    },
    {
      defaultValue: undefined,
    }
  );

  const { columnsArray } = useTable<any>({
    columnsTable: columnsTable,
    loading: isLoading,
    customOption: customOption,
    suffixColNames: ["actions"],
  });

  useEffect(() => {
    if (initialized) {
      const { offset, size, query } = queryParams;
      fetchMessages({
        offset,
        size,
        query,
      });
    }
  }, [initialized]);

  const search = (searched?: string) => {
    // Check if searched is undefined, null, or an empty string
    const searchTerm = searched?.toLocaleLowerCase().trim() || "";

    updateQueryParams({
      query: searchTerm,
      offset: 0,
    });

    fetchMessages({
      offset: 0,
      size: queryParams.size,
      query: searchTerm,
    });
  };

  const handlePassPaginateValues = (offset: number, size: number) => {
    updateQueryParams({ offset: offset, size: size });
    fetchMessages({ offset, size });
  };

  const onAddNewMessage = () => {
    modalActions.addModal(MODAL_TYPES.ADD_NEW_MESSAGE_MODAL, {
      onCreateNewMessageSuccess: async () => {
        await fetchMessages({
          offset: 0,
          size: queryParams.size,
          query: "",
        });
      },
    });
  };

  return (
    <Box>
      <Box display="flex" alignItems="center">
        <Text as="h3" fontSize="3xl">
          {t("titles.notificationsList")}
        </Text>
      </Box>
      <Box
        mb="4"
        mt="4"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <SearchInput
          searchPlaceholder={t("actions.search")}
          value={queryParams.query}
          onSearchInput={search}
          // showSearchButton={false}
        />
        <Button
          bg="#F39905"
          color="white"
          ml="2"
          pr="20px"
          pl="20px"
          ref={exportButton}
          onClick={onAddNewMessage}
        >
          &nbsp;New Message
          <Icon path={mdiPlus} size="25px" />
        </Button>
      </Box>
      <Table
        loading={isLoading}
        dataSource={messagesData?.items}
        columns={columnsArray || []}
      />
      <Pagination
        entityTitle="Message"
        loading={isLoading}
        pageTotalCount={messagesData?.totalCount || 0}
        perPageItems={queryParams.size || 10}
        passPaginateValues={handlePassPaginateValues}
      />
      {currentItem && (
        <RiderListModal
          isOpen={isRiderModalOpen}
          handleClose={() => {
            setIsRiderModalOpen(false);
            setCurrentItem(null);
          }}
          item={currentItem}
        />
      )}
    </Box>
  );
});
export default Notifications;
