import App from "./App";
import ReactDOM from "react-dom";
import "./i18n";

import * as Sentry from "@sentry/react";
import React from "react";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";

if (process.env.NODE_ENV !== "development") {
  console.log = () => {};
}

if (window.REACT_APP_API_URL) {
  Sentry.init({
    dsn: "https://e8c2bdef17354db89f37414794d1293d@o4504111123988480.ingest.sentry.io/4504201759948800",
    ignoreErrors: [
      // add strings here like:
      // 'Request failed with status code 422'
      // 'e.focus is not a functions
    ],
    integrations: [
      Sentry.browserApiErrorsIntegration({
        setTimeout: true,
        setInterval: true,
        requestAnimationFrame: true,
        XMLHttpRequest: true,
        eventTarget: true,
      }),
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.breadcrumbsIntegration({
        console: true,
        dom: true,
        fetch: true,
        history: true,
        xhr: true,
      }),
      Sentry.replayIntegration(),
      Sentry.captureConsoleIntegration({
        levels: ["error"],
      }),
      Sentry.reportingObserverIntegration(),
      Sentry.sessionTimingIntegration(),
    ],
    // debug: true, uncomment for debugging your application
    tracesSampleRate: 0.2, // Capture n% of the transactions
    sampleRate: 1.0,
    replaysSessionSampleRate: 0.1, // Sample rate at 10%
    replaysOnErrorSampleRate: 1.0, // 100% sample rate on errors
  });
}

ReactDOM.render(<App />, document.getElementById("root"));
