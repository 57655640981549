import { RiderCategory, RiderType } from "src/api/types/rider-type";
import { FORM_ITEM_TYPE } from "../../../../types/form";
import { panelTargetGroupsService } from "src/api/services/target-groups";
import { TFunction } from "i18next";
import { workingStatusService } from "src/api/services/working-status";

export const getCreateRiderFormItems = ({
  t,
  getAbortSignal,
  onCivilIdBlur,
  onEmployeeIdBlur,
  editable = false,
}: {
  t: TFunction;
  editable?: boolean;
  getAbortSignal: (requestName: string) => AbortController;
  onCivilIdBlur: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onEmployeeIdBlur: (e: React.ChangeEvent<HTMLInputElement>) => void;
}) => {
  return {
    employeeId: {
      label: t("fields.employeeId"),
      name: "employeeId",
      type: FORM_ITEM_TYPE.TextNoSpace,
      required: true,
      bidirectional: true,
      validate: onEmployeeIdBlur,
    },
    firstName: {
      label: t("fields.firstName"),
      name: "firstName",
      type: FORM_ITEM_TYPE.Text,
      required: true,
      bidirectional: true,
    },
    lastName: {
      label: t("fields.lastName"),
      name: "lastName",
      type: FORM_ITEM_TYPE.Text,
      required: true,
      bidirectional: true,
    },
    ...(editable
      ? {}
      : {
          rawPassword: {
            label: t("fields.password"),
            name: "rawPassword",
            type: FORM_ITEM_TYPE.Password,
            required: true,
            bidirectional: true,
          },
        }),
    riderType: {
      label: t("fields.riderType"),
      name: "riderType",
      type: FORM_ITEM_TYPE.Select,
      bidirectional: true,
      options: Object.keys(RiderType).map((key) => ({
        id: key,
        value: key,
        title: key,
      })),
    },
    riderCategory: {
      label: t("fields.riderCategory"),
      name: "riderCategory",
      type: FORM_ITEM_TYPE.Select,
      bidirectional: true,
      options: Object.keys(RiderCategory).map((key) => ({
        id: key,
        value: key,
        title: key,
      })),
    },
    ...(editable
      ? {
          currentWorkingStatusId: {
            label: t("fields.workingStatus"),
            name: "currentWorkingStatusId",
            type: FORM_ITEM_TYPE.Select,
            bidirectional: true,
            fetcher: () =>
              workingStatusService.getAll().then((res) =>
                res?.data.data.items?.map((item) => ({
                  title: item.title,
                  value: item?.id.toString(),
                }))
              ),
          },
          nextWorkingStatusId: {
            label: t("fields.workingStatusNextShift"),
            name: "nextWorkingStatusId",
            type: FORM_ITEM_TYPE.Select,
            bidirectional: true,
            fetcher: () =>
              workingStatusService.getAll().then((res) =>
                res?.data.data.items?.map((item) => ({
                  title: item.title,
                  value: item?.id.toString(),
                }))
              ),
          },
          targetGroupId: {
            label: t("fields.targetGroup"),
            name: "targetGroupId",
            type: FORM_ITEM_TYPE.Select,
            bidirectional: true,
            hasNoneOption: true,
            fetcher: () =>
              panelTargetGroupsService
                .getActives({}, getAbortSignal("targetGroups").signal)
                .then((res) =>
                  res.data.map((item) => ({
                    title: item?.name,
                    value: item?.id,
                  }))
                ),
          },
        }
      : {}),
    civilIdNo: {
      label: t("fields.civilIdNo"),
      name: "civilIdNo",
      type: FORM_ITEM_TYPE.Text,
      bidirectional: true,
      validate: onCivilIdBlur,
    },
    contactNo: {
      label: t("fields.contactNo"),
      name: "contactNo",
      type: FORM_ITEM_TYPE.Text,
      bidirectional: true,
    },
    driverLicenseNo: {
      label: t("fields.driverLicenseNo"),
      name: "driverLicenseNo",
      type: FORM_ITEM_TYPE.Text,
      bidirectional: true,
    },
  };
};
