import {
  Input
} from "@chakra-ui/react";
import React from "react";

export const FormGeneratorCustomInput = (props: any) => {
  const { formItem, field, isInvalid } = props;

  return (
      <Input
          {...{
            ...field,
            value: field.value?.replaceAll(' ','')
          }}
          {...formItem}
          onBlur={formItem.validate}
          isInvalid={isInvalid}
      />
  );
};
