export enum RiderType {
  None = "None",
  Maintenance = "Maintenance",
  Rider = "Rider",
}

export enum RiderCategory {
  None = "None",
  Dedicated = "Dedicated",
  SelfPickup = "SelfPickup",
  DoD = "DoD",
}

export enum RiderStatusType {
  None = "None",
  CheckIn = "CheckIn",
  ManualCheckout = "ManualCheckout",
  SystematicTooLateCheckIn = "SystematicTooLateCheckIn",
  SystematicTooLateExit = "SystematicTooLateExit",
}

export enum RiderWorkingStatusEnum {
  ON_DUTY = 1,
  ON_LEAVE = 2,
  ON_HOLD = 3,
  ACCIDENT = 4,
}
